const addItemToFilter = (element) => {
    
    if(!element) return false;

    const list = element.querySelector('.boost-pfs-filter-option-item-list');
    const li = document.createElement('li');
    
    li.className = 'boost-pfs-filter-option-item boost-pfs-filter-option-label';
    li.innerHTML = `
        <button role="checkbox" class="boost-pfs-filter-button">
            <span class="boost-pfs-check-box" role="checkbox" tabindex="0" aria-labelledby="Best Sellers"></span>
            <span class="boost-pfs-filter-option-value">Best Sellers</span>
            <span class="boost-pfs-filter-option-amount" aria-label=""></span>
        </button>
    `;

    const copyNode = list.firstChild.cloneNode(true);
    copyNode.setAttribute('data-abtest-collection', 'true');

    copyNode.querySelector('.boost-pfs-filter-option-value').innerText = "Best Sellers";

    copyNode.addEventListener('click', function(){
        window.location.href = '/collections/best-sellers';
    });

    if(window.location.pathname === '/collections/best-sellers'){
        copyNode.classList.add('selected');
    }

    if( !list.querySelector('li[data-abtest-collection="true"]') ){
        list.insertBefore(copyNode, list.firstChild);
    }

};

window.abtestCollection = function (){
    
    setTimeout(function(){
        const targetElement = document.querySelector("#CollectionProductGrid");
        if(!targetElement) return false;

        const filterTypeProduct = targetElement.querySelector('#boost-pfs-filter-tree-pf-pt-product-type');
        addItemToFilter(filterTypeProduct);
    }, 1300);
    
};

document.addEventListener('DOMContentLoaded', function(){
    window.abtestCollection();
    //comoolloas
});